.sidenav {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
}

.sidenav---sidenav---_2tBP {
    background: #ff8c00;
}

.sidenav---expanded---1KdUL {
    overflow-y: scroll;
}

.h1,
h1 {
    color: #ff8c00;
}

p {
    color: grey;
}

.faMenuIcons {
    color: white !important;
    height: 1.125em !important;
    width: 1.125em !important;
    size: 10px !important;
    vertical-align: middle;
}

.header-box {
    background-color: #fff;
    padding: 35px;
}

.header-box h1 {
    font-size: 30px;
    font-weight: 500;
    color: #ff8c00;
    line-height: 35px;
}

.header-box p {
    color: grey;
    padding: 15px 0 18px;
    line-height: 24px;
    font-size: 0.95em;
}

.footer-box h5 {
    font-size: 17px;
    color: #212121;
    margin-bottom: -9px;
    display: inline-block;
    font-weight: 500;
    border-bottom: 3px solid #ff8c00;
    margin-top: 40px;
}

.footer-box li {
    color: #777;
    margin-top: 10px;
    padding: 0;
}

.footer-box a {
    color: #777;
}

.footer-box a:hover {
    color: #777;
}

.footer-box a:visited {
    text-decoration: none;
}

.footer-box p {
    color: #777;
    font-size: 13px;
}

.footer-box ul {
    list-style: none;
    vertical-align: baseline;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.icon {
    color: red;
    margin-right: 1em;
}

.btn-top-menu {
    background-color: #ff8c00;
    border-color: #ff8c00;
}

.btn-top-menu:hover {
    border-color: #ff8c00;
    background-color: #ff8c00;
    opacity: 0.5;
}

.btn-top-menu a {
    color: white;
}

@media screen and (max-width: 400px) {
    h1 {
        font-size: 20px !important;
        line-height: 80%;
        margin-top: 10px;
    }
    h4 {
        font-size: 16px !important;
    }
}
